$primary-color: #4a4a4a;
$accent-color: #4d882c;
$third-color: #fc948b;
$main-green-color: #4d882c;

$light-primary-text-color: #fff;
$dark-primary-text-color: #000;

$border-color: #dadce0;
$border-dark-color: #4f4f50;

$background-color: #f8f9fa;
$skeleton-color: #e6e6e6;
$highlighted-background-color: #f1f3f4;
$selected-background-color: #eeeff0;
$subtle-background-highlight: #fdfcfc;

$success-color: #00897b;
$danger-color: #f44336;
$warning-color: #ffc008;

$credit-color: #ffd700;

$text-color: #202124;
$text-on-primary-color: #fff;
$text-on-accent-color: #fff;
$text-on-success-color: #fff;
$text-muted-color: #a4a4a4;
$text-on-highlighted-background-color: #3c4043;
