@use '../colors' as colors;

html,
body {
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;

  font-size: 14px;

  color: colors.$text-color;
  background-color: colors.$main-green-color;

  font-family: Roboto, 'Helvetica Neue', sans-serif;
  line-height: 20px;
}

* {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  line-height: 20px;
}

.mat-typography {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
  }
}

hr {
  border-color: colors.$border-color;
  opacity: 1;
}

app-root {
  height: 100%;
}

.page {
  flex: auto;

  overflow: auto;
}

.fixed-page {
  flex: auto;

  overflow: hidden;
}

a {
  text-decoration: none;

  color: colors.$accent-color;

  transition: color 0.25s ease-in-out;

  &:hover {
    color: colors.$primary-color;
  }
}
